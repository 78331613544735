import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails, Checkbox, FormGroup, FormControlLabel, Menu, MenuItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Color } from '../../assets/color';
import SqrBtnMain from '../../components/squareBtnMain';
import JobListCard from '../../components/jobListCard';
import LinkButton from '../../components/PaginationBtn';

import ipgSDK from '../../ipg-sdk/api';
import elasticlunr from 'elasticlunr';
import { type as Contract } from '../../constant/index';

import jobSeekerImg from '../../assets/jobseeker/jobSeekerImg.jpeg';
import ico_down from '../../assets/icons/ico_arrow_down.svg';
import ico_search from '../../assets/icons/ico_search.svg';
import ico_fold from '../../assets/icons/ico_fold_out.svg';
import ico_fold_in from '../../assets/icons/ico_fold_in.svg';
import ico_close from '../../assets/icons/ico_close.svg';
import checkbox from '../../assets/icons/ico_check_box.svg';
import checked from '../../assets/icons/ico_check_box_checked.svg';
import ico_left_g from '../../assets/icons/ico_arrow_left_g.svg';
import ico_left_b from '../../assets/icons/ico_arrow_left_b.svg';
import ico_right_g from '../../assets/icons/ico_arrow_right_g.svg';
import ico_right_b from '../../assets/icons/ico_arrow_right_b.svg';
import ico_badge from '../../assets/icons/ico_badge.svg';
import { Helmet } from 'react-helmet';


const style = theme => ({
    header:{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        [theme.breakpoints.up('mob')]: {
            height: 518
        },
        [theme.breakpoints.up('tabletv')]: {
            height: 480
        },
        [theme.breakpoints.up('tableth')]: {
            height: 640
        },
        [theme.breakpoints.up('desktop')]: {
            height: 800
        },
        [theme.breakpoints.up('largedesktop')]: {
            
        },
    },
    headerImg: {
        objectFit: 'cover',
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            height: 288
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '50%',
            height: 480
        },
        [theme.breakpoints.up('tableth')]: {
            height: 640
        },
        [theme.breakpoints.up('desktop')]: {
            height: 800
        },
    },
    headerTitle: {
        position: 'relative',
        [theme.breakpoints.up('mob')]: {
            width: '100%',
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '50%',
            height: 480
        },
        [theme.breakpoints.up('tableth')]: {
            height: 640
        },
        [theme.breakpoints.up('desktop')]: {
            height: 800
        },
    },
    title:{
        [theme.breakpoints.up('mob')]: {
            padding: '60px 24px',
            width: 240
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '176px 32px 0px',
            width: 288
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '256px 32px 0px',
            width: 360
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '208px 120px 0px',
            width: '80%',
            maxWidth: 480
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '240px 240px 0px',
        },
    },
    button: {
        [theme.breakpoints.up('mob')]: {
            display:'none'
        },
        [theme.breakpoints.up('desktop')]: {
            display:'flex',
            position: 'absolute',
            bottom: 0,
            right: 0
        }
    },
    jobSeekerTitle: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            paddingBottom: 32,
            maxWidth: 320
        },
        [theme.breakpoints.up('tabletv')]: {
        },
        [theme.breakpoints.up('tableth')]: {
            maxWidth: 'none',
            width: 600
        },
        [theme.breakpoints.up('desktop')]: {
            width:  '50%',
            paddingBottom: 0,
            maxWidth: 480
        },
        [theme.breakpoints.up('largedesktop')]: {
            width:  '50%',
            maxWidth: 600
        }
    },
    servicePara: {
        [theme.breakpoints.up('mob')]: {
            width: '100%'
        },
        [theme.breakpoints.up('desktop')]: {
            width:  '50%'
        }
    },
    main: {
        [theme.breakpoints.up('mob')]: {
          padding: '0px 24px',
            marginBottom: '30px'
    
        },
        [theme.breakpoints.up('tabletv')]: {
          padding: '0px 32px',
        },
        [theme.breakpoints.up('tableth')]: {
          padding: '0px 32px',
            marginBottom: '40px'
        },
        [theme.breakpoints.up('desktop')]: {
          padding: '0px 120px',
          marginBottom: '60px'
        },
        [theme.breakpoints.up('largedesktop')]: {
          padding: '0px 240px',
            marginBottom: '80px'
        },
      },
    block: {
        display:'flex', 
        flexDirection:'row', 
        justifyContent:'space-between', 
    },
    cardBlock: {
        display:'flex',
        flexDirection:'column',
        [theme.breakpoints.up('mob')]: {
            paddingTop: 80,
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: 120
        },
        [theme.breakpoints.up('desktop')]: {
            paddingTop: 200,
        },
    },
    resumeButtonBlock: {
        [theme.breakpoints.up('mob')]: {
            marginTop: 32,
        },
       
        [theme.breakpoints.up('desktop')]: {
            marginTop: 60,
            marginLeft: '50%' 
        },
    },
    submitButton: {
        backgroundColor: Color.intelligentBlue,
        borderRadius: 0,
        textTransform: 'none',
        border: 0,
        color: Color.white,
        fontFamily: 'sofia-pro',
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: 600,
        width: 240,
        height: 64,
        display: 'flex',
        flexDirection:'column',
        justifyContent:'center',
        boxShadow:'0px 2px 8px rgba(0, 0, 0, 0.08)',
        '&:active': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED'
        },
        '&:focus': {
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #377DED'
        },
        '&:hover': {
            cursor: 'pointer',
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED',
            boxShadow:'0px 8px 24px rgba(55, 125, 237, 0.1)',
        }
    },
    searchBar: {
        [theme.breakpoints.up('mob')]: {
            flexWrap:'wrap'
        },
        [theme.breakpoints.up('desktop')]: {
            flexWrap:'nowrap'
        },
    },
    form: {
        display: 'flex',
        
        [theme.breakpoints.up('mob')]: {
            width: '100%',
        },
        [theme.breakpoints.up('largedesktop')]: {
            width: 752,
        }
    },
    searchInputField: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
        },
        [theme.breakpoints.up('tabletv')]: {
            width: 'calc(100% - 240px)',
        },
        [theme.breakpoints.up('desktop')]: {
            width: '75%',
            maxWidth: 512
        },
        [theme.breakpoints.up('largedesktop')]: {
            width: 512
        },
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        fontFamily: 'sofia-pro',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.02em',
        '& input': {
            borderRadius: 0,
            border: '2px solid #E8E8E8',
            height: 64,
            padding: '0px 64px',
            fontFamily: 'sofia-pro',
            fontSize: '16px',
            lineHeight: '24px',
            '&:hover': {
                border: '2px solid #377DED'
             },
            '&:focus': {
                border: '2px solid #377DED',
                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)'
            },
            '&:active': {
                border: '2px solid #377DED',
            },
        }
    },
    searchIcon: {
        position: 'absolute',
        width: 32,
        left: 16, 
        top:16
    },
    searchButton: {
        backgroundColor: Color.intelligentBlue,
        borderRadius: 0,
        border: 0,
        color: Color.white,
        fontFamily: 'sofia-pro',
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: 600,
        width: 240,
        height: 64,
        display: 'flex',
        flexDirection:'column',
        justifyContent:'center',
        boxShadow:'0px 2px 8px rgba(0, 0, 0, 0.08)',
        '&:active': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED'
        },
        '&:focus': {
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #377DED'
        },
        '&:hover': {
            cursor: 'pointer',
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED',
            boxShadow:'0px 8px 24px rgba(55, 125, 237, 0.1)',
        },
        [theme.breakpoints.up('mob')]: {
            display: 'none'
         },
         [theme.breakpoints.up('tabletv')]: {
             display: 'flex',
         },
    },
    jobs: {
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.up('mob')]: {
            marginTop: 32
        },
        [theme.breakpoints.up('tabletv')]: {
            marginTop: 40
        },
        [theme.breakpoints.up('tableth')]: {
            marginTop: 60
        },
        [theme.breakpoints.up('desktop')]: {
            marginTop: 80
        },
    },
    filter: {
        width: 290,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('mob')]: {
           display: 'none'
        },
        [theme.breakpoints.up('desktop')]: {
            display: 'flex',
            margin: '0px 80px 0px 0px'
        },
    },
    filterMob: {
        overflow: 'scroll',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0px -12px',
        [theme.breakpoints.up('mob')]: {
            display: 'flex',
            marginTop: 32
        },
        [theme.breakpoints.up('tabletv')]: {
            marginTop: 40
        },
        [theme.breakpoints.up('tableth')]: {
            marginTop: 60
        },
        [theme.breakpoints.up('desktop')]: {
            display: 'none'
        },
    },
    filterButton: {
        height: 64,
        border: '2px solid #E8E8E8',
        margin: '0px 12px',
        padding: '0px 32px',
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        boxSizing: 'content-box',
        width: 'fit-content',
        backgroundColor: Color.white,
        '&:hover': {
            cursor: 'pointer',
            color: Color.lightBlue
        },
        '&:active': {
            backgroundColor: Color.lightBlue,
        },
    },
    filterMenu: {
        '& li': {
           boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
           '&:hover': {
               backgroundColor: Color.white
           }
        },
    },
    filterTitle: {
        color: Color.black,
        fontWeight: 700,
        fontSize: 16, 
        lineHeight: '24px',
        letterSpacing: '0.02em',
        [theme.breakpoints.up('mob')]: {
            display: 'none'
        },
        [theme.breakpoints.up('desktop')]: {
            display: 'flex'
        },
    },
    accordion: {
        boxShadow: 'none',
        '&::before': {
            backgroundColor: Color.white
        },
        [theme.breakpoints.up('mob')]: {
            width: 224,
            border: '2px solid #E8E8E8',
            margin: 12
        },
        [theme.breakpoints.up('desktop')]: {
            width: '100%',
            padding: '20px 0px',
            border: 'none',
            margin: 0
        },
        '& .MuiAccordionSummary-root': {
            minHeight: 32,
            [theme.breakpoints.up('mob')]: {
                height: 60,
            },
            [theme.breakpoints.up('desktop')]: {
                height: 32,
            }
        },
        '& .MuiAccordionSummary-content': {
            margin: 0,
        },
        '& .MuiIconButton-root': {
            padding: '0px 12px'
        }
    },
    accordionExpanded: {
       padding: '20px 0px 4px'
    },
    icon: {
        borderRadius: 0,
        width: 32,
        height: 32,
        boxShadow: 'none',
        backgroundImage: `url(${checkbox})`,
        'input ~ &': {
            margin: '0px 16px 0px 0px',
            padding: 0,
            '&:hover': {
                backgroundColor: 'none',
            }
        },
    },
    checkedIcon: {
        backgroundColor: Color.white,
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
          display: 'block',
          width: 32,
          height: 32,
          backgroundImage: `url(${checked})`,
          content: '""',
        },
        'input:hover ~ &': {
          backgroundColor: 'none',
        },
    },
    mobSelectList: {

    },
    selectList: {
        margin: '-12px 0px',
        padding: '40px 0px 20px'
    },
    mobSelectOption: {
        padding: '12px 0px',
        '&:hover': {
            color: Color.intelligentBlue
        },
    },
    mobSelectLabel:{
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 20,
        lineHeight: '32px',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.04em',  
        color: '#5C5E6B',
        fontFamily: 'sofia-pro',
    },
    selectOption: {
        padding: '12px 0px',
    },
    selectLabel:{
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 20,
        lineHeight: '32px',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.04em',  
        color: Color.midGrey,
        fontFamily: 'sofia-pro',
    },
    accordionDetail: {
        padding: 0
    },
    jobItems: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            marginTop: 32
        },
        [theme.breakpoints.up('tabletv')]: {
            marginTop: 40
        },
        [theme.breakpoints.up('tableth')]: {
            marginTop: 60
        },
        [theme.breakpoints.up('desktop')]: {
            width: 'calc(100% - 370px)',
            marginTop: 0
        },
    },
    jobItemsEmpty: {
        height: 480,
        [theme.breakpoints.up('mob')]: {
            width: 'calc(100% - 80px)',
            margin: '32px 0px 80px'
        },
        [theme.breakpoints.up('tabletv')]: {
            margin: '40px 0px 120px'
        },
        [theme.breakpoints.up('tableth')]: {
            margin: '60px 0px 120px'
        },
        [theme.breakpoints.up('desktop')]: {
            width: 'calc(100% - 450px)',
            margin: '80px 0px 200px'
        },
        backgroundColor: Color.lightBlue,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 40,
    },
    jobList: {
        display: 'flex',
        flexDirection:'column',
        width: '100%',
        [theme.breakpoints.up('mob')]: {
            margin: -12,
        },
        [theme.breakpoints.up('tableth')]: {
            margin: -16
        },
    },
    topPagination: {
        display:'flex', 
        justifyContent:'space-between', 
        width: '100%',
        [theme.breakpoints.up('mob')]: {
            marginBottom: 32
        },
        [theme.breakpoints.up('tabletv')]: {
            marginBottom: 40
        }
    },
    bottomPagination: {
        display:'flex', 
        justifyContent:'space-between', 
        width: '100%', 
        [theme.breakpoints.up('mob')]: {
           paddingTop: 24,
           paddingBottom: 80
        },
        [theme.breakpoints.up('tabletv')]: {
            paddingTop: 24,
            paddingBottom: 120
         },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: 32
        },
    },
    dialogClose: {
        [theme.breakpoints.up('mob')]: {
            width: 32,
            position: 'absolute',
            top: 32, 
            right: 32
         },
         [theme.breakpoints.up('tableth')]: {
            width: 64,
            position: 'relative',
            top: 'unset', 
            right:  'unset'
         },
         background: 'none',
         border: 'none',
         padding: 0,
         '&:hover': {
             cursor: 'pointer'
         }
    },
    dialogTitle: {
        position: 'relative',
        [theme.breakpoints.up('mob')]: {
           display: 'none'
         },
        [theme.breakpoints.up('tabletv')]: {
           display: 'flex',
           justifyContent: 'space-between',
           paddingTop: 60
        },
    },
    mobDialogTitle: {
        position: 'relative',
        [theme.breakpoints.up('mob')]: {
            display: 'block'
          },
         [theme.breakpoints.up('tabletv')]: {
            display: 'none'
         },
    },
    formInputField: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            padding: '12px 0px'
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '30px 0px'
        },
        maxWidth: 512,
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'sofia-pro',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.02em',
        '& label': {
            fontWeight: 700,
            color: '#000000'
        },
        '& input': {
            borderRadius: 0,
            border: '2px solid #E8E8E8',
            height: 64,
            marginTop: 24,
            paddingLeft: 24,
            fontFamily: 'sofia-pro',
            fontSize: '16px',
            lineHeight: '24px',
            '&:hover': {
                border: '2px solid #377DED'
             },
            '&:focus': {
                border: '2px solid #377DED',
                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)'
            },
            '&:active': {
                border: '2px solid #377DED',
            },
        }
    },
    dialogUpload: {
        backgroundColor: Color.lightBlue,
        borderRadius: 0,
        border: 0,
        color: Color.intelligentBlue,
        fontFamily: 'sofia-pro',
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: 600,
        width: 240,
        height: 64,
        display: 'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        boxShadow:'0px 2px 8px rgba(0, 0, 0, 0.08)',
        '&:active': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED',
            boxShadow: '0px 2px 8px rgba(55, 125, 237, 0.08)'
        },
        '&:focus': {
            background: Color.white,
            border: '2px solid #377DED'
        },
        '&:hover': {
            cursor: 'pointer',
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #F6F9FD',
            boxShadow:'0px 8px 24px rgba(55, 125, 237, 0.1)',
        }
    },
    checkbox: {
        padding: 0,
        '& input': {
            width: 32,
            height: 32
        },
       
    },
    dialogButton: {
        backgroundColor: Color.intelligentBlue,
        borderRadius: 0,
        border: 0,
        color: Color.white,
        fontFamily: 'sofia-pro',
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: 600,
        width: 240,
        height: 64,
        display: 'flex',
        flexDirection:'column',
        justifyContent:'center',
        boxShadow:'0px 2px 8px rgba(0, 0, 0, 0.08)',
        '&:active': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED'
        },
        '&:focus': {
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #377DED'
        },
        '&:hover': {
            cursor: 'pointer',
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED',
            boxShadow:'0px 8px 24px rgba(55, 125, 237, 0.1)',
        },
        '&:disabled': {
            color: Color.grey,
            backgroundColor: Color.borderGrey
        }
    },
    greatePlace: {
        height: 480,
        [theme.breakpoints.up('mob')]: {
            width: '100%',
        },
        [theme.breakpoints.up('desktop')]: {
            width: '50%',
        },
        alignItems: 'center',
        backgroundColor: Color.intelligentBlue,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        
    },
    bottomContent: {
        [theme.breakpoints.up('mob')]: {
            paddingLeft: '32px'
        },
        [theme.breakpoints.up('tableth')]: {
            paddingLeft: '60px'
        },
        
    },
    bottomLine: {
        [theme.breakpoints.up('mob')]: {
            margin: '40px 0px 15px'
        },
        [theme.breakpoints.up('tableth')]: {
            margin: '40px 0px 15px'
        },
        
    },
    greatePlaceImg:{
        position: 'relative',
        [theme.breakpoints.up('mob')]: {
            top: -172,
            width: 80,
            paddingRight: '32px'
        },
        [theme.breakpoints.up('tabletv')]: {
            paddingRight: '60px'
        },
        [theme.breakpoints.up('tableth')]: {
            width: 126,
            top: -133
        },
        [theme.breakpoints.up('desktop')]: {
            width: 108,
            top: -148
        },
        [theme.breakpoints.up('largedesktop')]: {
            width: 126,
            top: -133
        },
    },
    bottomImage: {
        height: 480,
        objectFit:'cover',
        [theme.breakpoints.up('mob')]: {
            width: '100%',
        },
        [theme.breakpoints.up('desktop')]: {
            width: '50%',
            
        },
    },
})

class JobSeeker extends React.Component {
    constructor() {
        super();
        this.state = {
            allList: [],
            results: [],
            lastResults: [],
            showList: [],
            currentPage: 1,
            totalPage: 1,
            leftEnd: false,
            rightEnd: false,
            loading: true,
            errMessage: '',
            total: 0,
            disciplines: [],
            selectedDisciplines: new Set(),
            locations: [],
            selectedLocations: new Set(),
            jobTypes: [],
            selectedJobTypes: new Set(),
            companies: [],
            selectedCompanies: new Set(),
            search: '',
            searchInfo: '',
            anchorEl1: null,
            mobileMoreAnchorEl1: null,
            anchorEl2: null,
            mobileMoreAnchorEl2: null,
            anchorEl3: null,
            mobileMoreAnchorEl3: null,
            anchorEl4: null,
            mobileMoreAnchorEl4: null,
            open: false,
            tabletv: false,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            file: null,
            errorMessage: '',
            successMessage: '',
            timer: null,
            check: false
        }
    }

    getJobs = () => {
        var current = Date.now();
        if(localStorage.searchTime === undefined || 
            localStorage.searchTime === null || 
            current - localStorage.searchTime > 360000 || 
            localStorage.jobs_0 === undefined ||
            localStorage.jobs_0 === '[]') {
            localStorage.searchTime = current;
            ipgSDK.getJobs().then(res => {
                localStorage.jobs_0 = JSON.stringify(res); 
                this.setState({ allList: res }, 
                    () => { 
                        this.initializeJobs()
                        this.setState({ total: this.state.allList.length, results: this.state.allList, });
                        if(this.state.allList.length > 10) {
                            this.setState({ showList: this.state.allList.slice(0, 10), totalPage: Math.ceil(this.state.allList.length / 10), leftEnd: true, rightEnd: ! (this.state.currentPage < Math.ceil(this.state.allList.length / 10))  })
                        }
                        else {
                            this.setState({ showList: this.state.allList, leftEnd: true, rightEnd: true })
                        } 
                    }
                )
            }).catch(err => { 
                console.log(err); 
                this.setState({ errMessage: err.message }) 
            }); 
        }
        else {
            this.setState({ allList: JSON.parse(localStorage.jobs_0) },  () => { 
                this.initializeJobs()
                this.setState({ total: this.state.allList.length, results: this.state.allList, });
                if(this.state.allList.length > 10) {
                    this.setState({ showList: this.state.allList.slice(0, 10), totalPage: Math.ceil(this.state.allList.length / 10), leftEnd: true, rightEnd: ! (this.state.currentPage < Math.ceil(this.state.allList.length / 10))  })
                }
                else {
                    this.setState({ showList: this.state.allList, leftEnd: true, rightEnd: true })
                } 
            })
        }
    }
    initializeJobs = () => {
        var companies = new Set();
        var disciplines = new Set();
        var locations = new Set();
        var jobTypes = new Set();
        for(let i = 0; i < this.state.allList.length; i++) {
            this.state.allList[i].companyName && companies.add(this.state.allList[i].companyName);
            if(this.state.allList[i].jobFunctions !== null && this.state.allList[i].jobFunctions !== undefined) {
                if(this.state.allList[i].jobFunctions[0]){
                    var tempCategory = this.titleCase(this.state.allList[i].jobFunctions[0].replaceAll("_", " "));
                    disciplines.add(tempCategory);
                }
            }
            if(this.state.allList[i].ipgLocation !== null && this.state.allList[i].ipgLocation.province !== null && this.state.allList[i].ipgLocation.city !== null) locations.add(this.state.allList[i].ipgLocation.province + ", " + this.state.allList[i].ipgLocation.city);
            else if(this.state.allList[i].ipgLocation !== null && this.state.allList[i].ipgLocation.province !== null) locations.add(this.state.allList[i].ipgLocation.province);
            else if(this.state.allList[i].ipgLocation !== null && this.state.allList[i].ipgLocation.city !== null) locations.add(this.state.allList[i].ipgLocation.city);
            if(this.state.allList[i].type !== null && this.state.allList[i].type !== undefined &&  Array.isArray(this.state.allList[i].type)) jobTypes.add(this.titleCase(this.state.allList[i].type.join(' ').replaceAll("_", " ")))
            if(this.state.allList[i].type !== null && this.state.allList[i].type !== undefined && (!Array.isArray(this.state.allList[i].type))) jobTypes.add(this.titleCase(this.state.allList[i].type.replaceAll("_", " ")))
        }
        this.setState({
            disciplines:  Array.from(disciplines),
            companies:  Array.from(companies),
            locations:  Array.from(locations).sort(),
            jobTypes:  Array.from(jobTypes)
        }, () => {console.log(this.state)})
    }

    titleCase = (str) => { 
        if(str){
            var newStr = str.split(" ");    
            for(var i = 0; i<newStr.length; i++) {
                newStr[i] = newStr[i].slice(0,1).toUpperCase() + newStr[i].slice(1).toLowerCase();
            }    
            return newStr.join(" ");
        } 
    }


    componentDidMount() {
        this.getDimensions(); 
        this.getJobs()
        //add dimensions listener for window resizing
        window.addEventListener('resize', this.getDimensions); 
    }
      
    //remove listener on page exit
    componentWillUnmount() {
        window.removeEventListener('resize', this.getDimensions); 
    }
    
    //actually set the state to the window dimensions
    getDimensions = () => {
        const query = ['(max-width: 961px)']
        const mql = query.map(q => window.matchMedia(q).matches) ;
        this.setState({ 
            tabletv: mql[0],
        });
    }

    handleSelect = (list) => {
        var results = list;
        if(this.state.selectedDisciplines.size > 0) {
            results = list.filter(job => job.jobFunctions != null && this.state.selectedDisciplines.has(this.titleCase((job.jobFunctions[0]&&job.jobFunctions[0].replaceAll("_", " ")))));
        }
        if(this.state.selectedLocations.size > 0) {
            results = results.filter(job => job.ipgLocation !== null && ((job.ipgLocation.province !== null && job.ipgLocation.city !== null && this.state.selectedLocations.has(job.ipgLocation.province + ", " +  job.ipgLocation.city))
                || (job.ipgLocation.province !== null && this.state.selectedLocations.has(job.ipgLocation.province))
                || ( job.ipgLocation.city !== null && this.state.selectedLocations.has(job.ipgLocation.city))
            ))
        }
        if(this.state.selectedJobTypes.size > 0) {
            results = results.filter(job => job.type&&this.state.selectedJobTypes.has(this.titleCase(job.type.replaceAll("_", " "))))
        }
        if(this.state.selectedCompanies.size > 0) {
            results = results.filter(job => this.state.selectedCompanies.has(job.companyName))
        }
        this.setState({
            results: results,
            showList: results.slice(0, 10),
            currentPage: 1,
            totalPage: Math.ceil(results.length / 10),
            leftEnd: true,
            rightEnd: ! (1 < Math.ceil(results.length / 10)),
            total: results.length
        }, () => console.log(this.state));
    }

    handleDisciplineSelect = (event) => {
        var discipline = event.target.checked;
        var prev = this.state.selectedDisciplines;
        if(discipline) prev.add(event.target.name);
        else prev.delete(event.target.name);
        this.setState({ selectedDisciplines: prev }, () => {
            if(this.state.search != '') {
                this.setState({ lastResults: this.state.results })
                if(this.state.lastResults.length > 0) this.handleSelect(this.state.lastResults);
                else this.handleSelect(this.state.results);
            }
            else this.handleSelect(this.state.allList)
        })
    }

    handleLocationSelect = (event) => {
        var location = event.target.checked;
        var prev = this.state.selectedLocations;
        if(location) prev.add(event.target.name);
        else prev.delete(event.target.name);
        this.setState({ selectedLocations: prev }, () => {
            if(this.state.search != '') {
                this.setState({ lastResults: this.state.results })
                if(this.state.lastResults.length > 0) this.handleSelect(this.state.lastResults);
                else this.handleSelect(this.state.results);
            }
            else this.handleSelect(this.state.allList)
        })
    }

    handleJobTypeSelect = (event) => {
        var jobType = event.target.checked;
        var prev = this.state.selectedJobTypes;
        if(jobType) prev.add(event.target.name);
        else prev.delete(event.target.name);
        this.setState({ selectedJobTypes: prev }, () => {
            if(this.state.search != '') {
                this.setState({ lastResults: this.state.results })
                if(this.state.lastResults.length > 0) this.handleSelect(this.state.lastResults);
                else this.handleSelect(this.state.results);
            }
            else this.handleSelect(this.state.allList)
        })
    }

    handleCompanySelect = (event) => {
        var company = event.target.checked;
        var prev = this.state.selectedCompanies;
        if(company) prev.add(event.target.name);
        else prev.delete(event.target.name);
        this.setState({ selectedCompanies: prev }, () => {
            if(this.state.search != '') {
                this.setState({ lastResults: this.state.results })
                if(this.state.lastResults.length > 0) this.handleSelect(this.state.lastResults);
                else this.handleSelect(this.state.results);
            }
            else this.handleSelect(this.state.allList)
        })
    }

    handleSearchInfo = (event) => {
        event.preventDefault();
        var search = event.target.value;
        this.setState({
            searchInfo: search
        })
    }

    handleSearch =(e) => {
        console.log('hanlde search');
        e.preventDefault();
        if(this.state.searchInfo){
          ipgSDK
          .getJobs(this.state.searchInfo)
          .then((res) => {
            console.log('initial');
              this.setState({ allList: res }, () => {
                this.initializeJobs();
                this.setState({
                  total: this.state.allList.length,
                  results: this.state.allList,
                  currentPage: 1,
                });
                console.log('search length', this.state.allList.length)
                if (this.state.allList.length > 10) {
                  console.log('deatl with length > 10')
                  this.setState({
                    showList: this.state.allList.slice(0, 10),
                    totalPage: Math.ceil(this.state.allList.length / 10),
                    leftEnd: true,
                    rightEnd: !(1 < Math.ceil(this.state.allList.length / 10)),
                  });
                } else {
                  this.setState({
                    showList: this.state.allList,
                    totalPage: 1,
                    leftEnd: true,
                    rightEnd: true,
                  });
                }
              });
            
          })
          .catch((err) => {
            console.log(err);
            if (this._isMounted) { // 检查组件是否仍然挂载
              this.setState({ errMessage: err.message });
            }
          });
        }else{
          this.getJobs();
        }
         
      }

    // handleSearch = e => {
    //     e.preventDefault();
    //     this.setState({
    //         search: this.state.searchInfo
    //     })
    //     if(this.state.searchInfo != '') {
    //         var allList = this.state.allList.map(job => (
    //             {
    //                 id: job.id,
    //                 company: job.company,
    //                 title: job.title
    //             }
    //         ))
    //         var hashmap = this.state.allList.reduce(function(map, obj) {
    //             map[obj.id] = obj;
    //             return map;
    //         }, {});
    //         var index = elasticlunr(function () {
    //             this.addField('company')
    //             this.addField('title')
    //             this.setRef('id')
    //             this.saveDocument(false);
    //         });
    //         for(let i = 0; i < allList.length; i++) {
    //             index.addDoc(allList[i])
    //         }
    //         var output = index.search(this.state.searchInfo, {expand: true});
    //         this.setState({
    //             results: output.map(o => hashmap[o.ref])
    //         }, () => {this.handleSelect(this.state.results)})
    //     }
    //     else this.setState({ results: this.state.allList }, () => {this.handleSelect(this.state.results)})
    // }

    handleLeft = () => {
        if(this.state.currentPage === 2) {
            this.setState({
                showList: this.state.results.slice(0, 10),
                leftEnd: true,
                rightEnd: false
            })
        }
        else {
            this.setState({ showList: this.state.results.slice(10 * (this.state.currentPage - 2) - 1, 10 * (this.state.currentPage - 2) + 9), leftEnd: ! (this.state.currentPage > 1) , rightEnd: ! (this.state.currentPage < Math.ceil(this.state.results.length / 10))  })
        }
        this.setState({ currentPage: this.state.currentPage - 1 });
    }

    handleRight = () => {
        if(this.state.currentPage === this.state.totalPage - 1) {
            this.setState({
                showList: this.state.results.slice(this.state.currentPage * 10 - 1, this.state.currentPage * 10 + 9),
                leftEnd: false,
                rightEnd: true
            }, () => {console.log(this.state)})
        }
        else {
            this.setState({ showList: this.state.results.slice(this.state.currentPage * 10 - 1, this.state.currentPage * 10 + 9), leftEnd: ! (this.state.currentPage >= 1) , rightEnd: ! (this.state.currentPage < Math.ceil(this.state.results.length / 10))  },  () => {console.log(this.state)})
        }
        this.setState({ currentPage: this.state.currentPage + 1 });
    }

    handleMenuClose1 = () => {
        this.setState({anchorEl1: null});
        this.handleMobileMenu1();
    };


    handleMobileMenu1 = event => {
        const {mobileMoreAnchorEl1} = this.state;
        if(Boolean(mobileMoreAnchorEl1)) {
            this.setState({mobileMoreAnchorEl1: null});
        }
        else this.setState({mobileMoreAnchorEl1: event.currentTarget});
    };

    handleMenuClose2 = () => {
        this.setState({anchorEl2: null});
        this.handleMobileMenu2();
    };


    handleMobileMenu2 = event => {
        const {mobileMoreAnchorEl2} = this.state;
        if(Boolean(mobileMoreAnchorEl2)) {
            this.setState({mobileMoreAnchorEl2: null});
        }
        else this.setState({mobileMoreAnchorEl2: event.currentTarget});
    };

    handleMenuClose3 = () => {
        this.setState({anchorEl3: null});
        this.handleMobileMenu3();
    };


    handleMobileMenu3 = event => {
        const {mobileMoreAnchorEl3} = this.state;
        if(Boolean(mobileMoreAnchorEl3)) {
            this.setState({mobileMoreAnchorEl3: null});
        }
        else this.setState({mobileMoreAnchorEl3: event.currentTarget});
    };


    handleMenuClose4 = () => {
        this.setState({anchorEl4: null});
        this.handleMobileMenu4();
    };


    handleMobileMenu4 = event => {
        const {mobileMoreAnchorEl4} = this.state;
        if(Boolean(mobileMoreAnchorEl4)) {
            this.setState({mobileMoreAnchorEl4: null});
        }
        else this.setState({mobileMoreAnchorEl4: event.currentTarget});
    };

    handleClose = e => {
        e.preventDefault();
        this.setState({open: ! this.state.open , successMessage: '', errorMessage: ''})
    }

    handleUpload = (e) => {
        clearTimeout(this.state.timer)
        if (e.target.files[0].size / 1024 / 1024 > 10) {
          let timer = setTimeout(() => {
            this.setState({ open: true, errorMessage: 'The files must not exceed 10 MB',  });
            this.input.value = ''
          }, 100)
          this.setState({
            timer: timer
          })
          return
        }
        this.setState({
          file: e.target.files,
        })
    }

    handleRemove = () => {
        this.setState({
          file: null,
        })
      }

    handleCheck = e => {
        this.setState({ check: !this.state.check })
    }

    handleSubmit = e => {

    }



    render() {
        const { classes, menuClicked } = this.props;
        const {mobileMoreAnchorEl1, mobileMoreAnchorEl2, mobileMoreAnchorEl3, mobileMoreAnchorEl4} = this.state;
        const isMobileMenuOpen1 = Boolean(mobileMoreAnchorEl1);
        const isMobileMenuOpen2 = Boolean(mobileMoreAnchorEl2);
        const isMobileMenuOpen3 = Boolean(mobileMoreAnchorEl3);
        const isMobileMenuOpen4 = Boolean(mobileMoreAnchorEl4);

        const renderMobileMenu1 = (
            <Menu
                anchorEl={mobileMoreAnchorEl1}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
                open={isMobileMenuOpen1}
                onClose={this.handleMenuClose1}
                elevation={0}
                className={classes.filterMenu}
            >
                <MenuItem onClick={this.handleMobileMenuClose1} >
                    <FormGroup className={classes.mobSelectList}>
                        {this.state.disciplines.map(d => 
                                {
                                    return(
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedDisciplines.has(d)} onChange={e => this.handleDisciplineSelect(e)} name={d} key={d} icon={<span className={classes.icon}/>} checkedIcon={<span className={classes.checkedIcon} />}/>}
                                            label={d}
                                            key={d}
                                            classes={{root:classes.mobSelectOption, label:classes.mobSelectLabel}}
                                        />
                                    )
                                }
                            )}
                    </FormGroup>
                </MenuItem>
            </Menu>
        );

        const renderMobileMenu2 = (
            <Menu
                anchorEl={mobileMoreAnchorEl2}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
                open={isMobileMenuOpen2}
                onClose={this.handleMenuClose2}
                elevation={0}
                className={classes.filterMenu}
            >
                <MenuItem onClick={this.handleMobileMenuClose2} >
                    <FormGroup className={classes.mobSelectList}>
                            {this.state.locations.map(d => 
                                {
                                    return(
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedLocations.has(d)} onChange={this.handleLocationSelect} name={d} key={d} icon={<span className={classes.icon}/>} checkedIcon={<span className={classes.checkedIcon} />}/>}
                                            label={d}
                                            key={d}
                                            classes={{root:classes.mobSelectOption, label:classes.mobSelectLabel}}
                                        />
                                    )
                                }
                            )}
                    </FormGroup>
                </MenuItem>
            </Menu>
        );

        const renderMobileMenu3 = (
            <Menu
                anchorEl={mobileMoreAnchorEl3}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
                open={isMobileMenuOpen3}
                onClose={this.handleMenuClose3}
                elevation={0}
                className={classes.filterMenu}
            >
                <MenuItem onClick={this.handleMobileMenuClose3} >
                    <FormGroup className={classes.mobSelectList}>
                            {this.state.jobTypes.map(d => 
                                {
                                    var temp = "";
                                    if(Contract.hasOwnProperty(d)) temp = Contract[d];
                                    else temp = d;
                                    return(
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedJobTypes.has(d)} onChange={this.handleJobTypeSelect} name={d} key={d} icon={<span className={classes.icon}/>} checkedIcon={<span className={classes.checkedIcon} />}/>}
                                            label={temp}
                                            key={temp}
                                            classes={{root:classes.mobSelectOption, label:classes.mobSelectLabel}}
                                        />
                                    )
                                }
                            )}
                    </FormGroup>
                </MenuItem>
            </Menu>
        );

        const renderMobileMenu4 = (
            <Menu
                anchorEl={mobileMoreAnchorEl4}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
                open={isMobileMenuOpen4}
                onClose={this.handleMenuClose4}
                elevation={0}
                className={classes.filterMenu}
            >
                <MenuItem onClick={this.handleMobileMenuClose4} >
                    <FormGroup className={classes.mobSelectList}>
                            {this.state.companies.map(d => 
                                {
                                    return(
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedCompanies.has(d)} onChange={this.handleCompanySelect} name={d} key={d} icon={<span className={classes.icon}/>} checkedIcon={<span className={classes.checkedIcon} />}/>}
                                            label={d}
                                            key={d}
                                            classes={{root:classes.mobSelectOption, label:classes.mobSelectLabel}}
                                        />
                                    )
                                }
                            )}
                    </FormGroup>
                </MenuItem>
            </Menu>
        );

        const Popup = (
            <React.Fragment>
                <Dialog open={this.state.open} onClose={this.handleClose} fullScreen={this.state.tabletv} 
                    PaperProps={{
                        style: {
                            padding: this.state.tabletv ? 32 : '0px 60px 60px',
                            minWidth: this.state.tabletv ? 'none' : 840,
                        }
                    }}
                >
                    <div className={classes.dialogTitle}>
                        <div className={`h1`} role="heading" aria-level="2">
                            {this.state.errorMessage !== '' ? 'Sorry, something went wrong.' : this.state.successMessage !== '' ?  'Resume sent!' : 'Send us your resume' }
                        </div>
                        <button className={classes.dialogClose} onClick={this.handleClose}>
                            <img src={ico_close} alt="ico_close" style={{width: 64}}></img>
                        </button>
                    </div>
                    <div className={classes.mobDialogTitle}>
                            <button className={classes.dialogClose} onClick={this.handleClose}>
                                <img src={ico_close} alt="ico_close" style={{width: 64}}></img>
                            </button>
                            <div className={`h1`} role="heading" aria-level="2">
                                {this.state.errorMessage !== '' ? 'Sorry, something went wrong.' : this.state.successMessage !== '' ?  'Resume sent!' : 'Send us your resume' }
                            </div>
                    </div>
                    <div style={this.state.errorMessage === '' && this.state.successMessage === '' ? {display: 'block'} : {display:'none'}}>
                        <div className={classes.formInputField}>
                            <label for="firstname">
                                <span>First Name </span>
                                <span style={{color: Color.intelligentBlue}}>*</span>
                            </label>
                            <input type="text" required placeholder="First Name" id="firstname" name="firstname" ></input>
                        </div>
                    
                    
                        <div className={classes.formInputField}>
                            <label for="lastname">
                                <span>Last Name </span>
                                <span style={{color: Color.intelligentBlue}}>*</span>
                            </label>
                            <input type="text" required placeholder="Last Name" id="lastname" name="lastname" ></input>
                        </div>

                        <div className={classes.formInputField}>
                            <label for="email">
                                <span> Email</span>
                                <span style={{color: Color.intelligentBlue}}>*</span>
                            </label>
                            <input type="email" required placeholder="Email" id="email" name="email"></input>
                        </div>

                        <div className={classes.formInputField}>
                            <label for="phone">
                                <span>Phone Number</span>
                                <span style={{color: Color.intelligentBlue}}>*</span>
                            </label>
                            <input type="text" required placeholder="Phone Number" id="phone" name="phone"></input>
                        </div>
                        <div  style={{ display: 'flex', flexDirection: 'column', fontFamily: 'sofia-pro',}}>
                            <label for="resume" style={{marginBottom: 16}}>
                                <span>Resume</span>
                                <span style={{color: Color.intelligentBlue}}>*</span>
                            </label>
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                <div style={this.state.file !== null ? {display: 'flex', border: '2px solid #E8E8E8', height: 16, width: 200, padding: '24px 20px', marginRight: 24} : {display:'none'}}>
                                    <p className="cap" style={{color:Color.darkGrey, whiteSpace:'nowrap', textOverflow:'ellipsis', overflow:'hidden'}}>{this.state.file !== null ? this.state.file[0].name : ''}</p>
                                </div>
                                {this.state.file !== null ? 
                                <button className={classes.dialogUpload} onClick={this.handleRemove}>Remove</button> :
                                <div>
                                    <input 
                                        style={{display: 'none'}}
                                        name="file" type="file"   
                                        ref={el => this.input = el}
                                        onChange={this.handleUpload} 
                                        id="contained-button-file"
                                        accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    >
                                    </input>
                                    <label htmlFor="contained-button-file">
                                        <span className={classes.dialogUpload} >
                                            Upload a Resume
                                        </span>
                                    </label>
                                </div>
                                }           
                                
                            </div>

                            <div style={{marginTop: 60, display: 'flex', }}>
                                <Checkbox
                                    checked={this.state.check}
                                    style={{ marginRight: 8, height: 32 }}
                                    className={classes.checkbox}
                                    onChange={this.handleCheck}
                                    icon={<span className={classes.icon}/>} 
                                    checkedIcon={<span className={classes.checkedIcon} />}
                                />
                                <p >
                                    I give my express written consent for IntelliPro (including its vendors and affiliates) to contact me using texting tools. I understand that I may withdraw my consent at any time.
                                </p>
                            </div>

                            <button className={classes.dialogButton} style={{marginTop: 60}} disabled={!this.state.check} onClick={this.handleSubmit}>Apply</button>
                            
                        </div>
                    </div>
                    <div style={this.state.errorMessage !== '' ? {display:'block'}: {display: 'none'}}>
                        <p style={{marginTop: 60}}>{this.state.errorMessage}</p>
                        <button className={classes.dialogButton} style={{marginTop: 60}} onClick={this.handleClose}>Retry</button>
                    </div>
                    <div style={this.state.successMessage !== '' ? {display:'block'}: {display: 'none'}}>
                        <p style={{marginTop: 60}}>You resume has been sent. Thanks for your interest.</p>
                        <button className={classes.dialogButton} style={{marginTop: 60}} onClick={this.handleClose}>Okay</button>
                    </div>
                </Dialog>
            </React.Fragment>
            
        )

        return(
            <div>
                <Helmet>
                    <title>Job Seeker</title>
                    <meta property="og:title" content="Job opportunities, customized resume building, hiring, job market trends"/>
                    <meta property="og:description" content="InelliPro offers a customized approach to your job search. We help our candidates define their career goals and match them with opportunities that best suit their professional skills and career aspirations. Our recruiters will help you refine your resume, prepare you for job interviews, and inform you about the latest hiring and salary trends. "/>
                    <meta name="description" content="InelliPro offers a customized approach to your job search. We help our candidates define their career goals and match them with opportunities that best suit their professional skills and career aspirations. Our recruiters will help you refine your resume, prepare you for job interviews, and inform you about the latest hiring and salary trends. "/>
                </Helmet>
                <div className={classes.header}>
                    <div className={classes.headerTitle}>
                        <title className={classes.title}>Land Your Dream Job</title>
                        {/* <div className={classes.button}>
                            <a href="/jobseeker#jobs">
                                <SqrBtnMain icons={ico_down} content={'Search Job Opportunities'}/>
                            </a>
                        </div> */}
                    </div>
                    <img src={jobSeekerImg} alt="job seeker" className={classes.headerImg}></img>
                </div>
                <div className={classes.main}>
                    <div className={`${classes.block} ${classes.cardBlock}`} style={{flexDirection:'row', flexWrap:'wrap', alignItems: 'flex-start'}}>
                        <div className={`h1 ${classes.jobSeekerTitle}`} role="heading" aria-level="1">Discover the IntelliPro Difference</div>
                        <p className={`normal ${classes.servicePara}`}>At IntelliPro, we want to learn about our job candidates so that we can most effectively advocate for them in the employment market. Our recruiters will invest time and energy into understanding your current and longer-term career goals so that we can match you with great opportunities that best suit your skills, objectives and preferred work arrangement. We also can help you refine your resume, prepare for job interviews and keep you apprised of the latest hiring and salary trends in your market and industry. In short, we are staunchly committed to setting you up for success.</p>
                    </div>
                    <div className={classes.resumeButtonBlock}>
                        <a href="mailto:resume@intelliprogroup.com">
                            <Button className={classes.submitButton} style={{width: 305}}>Send Us Your Resume</Button>
                        </a>
                    </div>

                    {/* <div className={`${classes.block} ${classes.cardBlock} ${classes.searchBar}`} style={{flexDirection:'row', alignItems: 'flex-start', }} id="jobs">
                        <div className={`h1 ${classes.jobSeekerTitle}`} role="heading" aria-level="1">All Jobs</div>
                        <form className={classes.form} id="form" onSubmit={e => { this.handleSearch(e); return false;}} role="search">
                            <div className={classes.searchInputField}>
                                <img src={ico_search} alt="search_icon" className={classes.searchIcon}></img>
                                <input type="search" placeholder="Search job title or company..." id="search" name="search" value={this.state.searchInfo} onChange={ e => this.handleSearchInfo(e)} role="search" aria-label="search" ></input>
                            </div>
                            <button className={classes.searchButton} type="submit">Search</button>
                        </form>
                    </div>
                    <div className={classes.jobs}>
                        <div className={classes.filter}>
                            <p className={classes.filterTitle}>Filters</p>
                            <div style={{marginTop: 24}}>
                                <Accordion classes={{root: classes.accordion, expanded: classes.accordionExpanded}}>
                                    <AccordionSummary style={{padding: 0, margin: 0}}
                                        expandIcon={<img src={ico_fold} alt="icons" style={{width:32, padding: 0}} />}
                                    >
                                        <h3>Discipline</h3>
                                    </AccordionSummary>
                                    <AccordionDetails classes={{root: classes.accordionDetail}}>
                                        <FormGroup className={classes.selectList}>
                                            {this.state.disciplines.map(d => 
                                                    {
                                                        return(
                                                            <FormControlLabel
                                                                control={<Checkbox checked={this.state.selectedDisciplines.has(d)} onChange={e => this.handleDisciplineSelect(e)} name={d} key={d} icon={<span className={classes.icon}/>} checkedIcon={<span className={classes.checkedIcon} />}/>}
                                                                label={d}
                                                                key={d}
                                                                classes={{root:classes.selectOption, label:classes.selectLabel}}
                                                            />
                                                        )
                                                    }
                                                )}
                                        </FormGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion classes={{root: classes.accordion}}>
                                    <AccordionSummary style={{padding: 0, margin: 0}}
                                        expandIcon={<img src={ico_fold} alt="icons" style={{width:32, padding: 0}} />}
                                    >
                                        <h3>Location</h3>
                                    </AccordionSummary>
                                    <AccordionDetails classes={{root: classes.accordionDetail}}>
                                        <FormGroup className={classes.selectList}>
                                                {this.state.locations.map(d => 
                                                    {
                                                        return(
                                                            <FormControlLabel
                                                                control={<Checkbox checked={this.state.selectedLocations.has(d)} onChange={this.handleLocationSelect} name={d} key={d} icon={<span className={classes.icon}/>} checkedIcon={<span className={classes.checkedIcon} />}/>}
                                                                label={d}
                                                                key={d}
                                                                classes={{root:classes.selectOption, label:classes.selectLabel}}
                                                            />
                                                        )
                                                    }
                                                )}
                                        </FormGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion classes={{root: classes.accordion}}>
                                    <AccordionSummary style={{padding: 0}}
                                        expandIcon={<img src={ico_fold} alt="icons" style={{width:32}} />}
                                    >
                                        <h3>Job Type</h3>
                                    </AccordionSummary>
                                    <AccordionDetails classes={{root: classes.accordionDetail}}>
                                        <FormGroup className={classes.selectList}>
                                                {this.state.jobTypes.map(d => 
                                                    {
                                                        var temp = "";
                                                        if(Contract.hasOwnProperty(d)) temp = Contract[d];
                                                        else temp = d;
                                                        return(
                                                            <FormControlLabel
                                                                control={<Checkbox checked={this.state.selectedJobTypes.has(d)} onChange={this.handleJobTypeSelect} name={d} key={d} icon={<span className={classes.icon}/>} checkedIcon={<span className={classes.checkedIcon} />}/>}
                                                                label={temp}
                                                                key={temp}
                                                                classes={{root:classes.selectOption, label:classes.selectLabel}}
                                                            />
                                                        )
                                                    }
                                                )}
                                        </FormGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion classes={{root: classes.accordion}}>
                                    <AccordionSummary style={{padding: 0}}
                                        expandIcon={<img src={ico_fold} alt="icons" style={{width:32}} />}
                                    >
                                        <h3>Companies</h3>
                                    </AccordionSummary>
                                    <AccordionDetails classes={{root: classes.accordionDetail}}>
                                        <FormGroup className={classes.selectList}>
                                                {this.state.companies.map(d => 
                                                    {
                                                        return(
                                                            <FormControlLabel
                                                                control={<Checkbox checked={this.state.selectedCompanies.has(d)} onChange={this.handleCompanySelect} name={d} key={d} icon={<span className={classes.icon}/>} checkedIcon={<span className={classes.checkedIcon} />}/>}
                                                                label={d}
                                                                key={d}
                                                                classes={{root:classes.selectOption, label:classes.selectLabel}}
                                                            />
                                                        )
                                                    }
                                                )}
                                        </FormGroup>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>

                        <div className={classes.filterMob}>
                            <button className={classes.filterButton} onClick={this.handleMobileMenu1}>
                                <h3 style={{whiteSpace: 'nowrap'}}>Discipline {this.state.selectedDisciplines.size > 0 ? '(' + this.state.selectedDisciplines.size + ')' :''}</h3>
                                <img src={ico_fold} alt="fold_icon" style={this.state.isMobileMenuOpen1 ? {paddingLeft: 16, transform:'scaleY(-1)'} : {paddingLeft: 16}}></img>
                            </button>
                            <button className={classes.filterButton} onClick={this.handleMobileMenu2}>
                                <h3 style={{whiteSpace: 'nowrap'}}>Location {this.state.selectedLocations.size > 0 ? '(' + this.state.selectedLocations.size + ')' :''}</h3>
                                <img src={ico_fold} alt="fold_icon" style={this.state.isMobileMenuOpen2 ? {paddingLeft: 16, transform:'scaleY(-1)'} : {paddingLeft: 16}}></img>
                            </button>
                            <button className={classes.filterButton} onClick={this.handleMobileMenu3}>
                                <h3 style={{whiteSpace: 'nowrap'}}>Job Type {this.state.selectedJobTypes.size > 0 ? '(' + this.state.selectedJobTypes.size + ')' :''}</h3>
                                <img src={ico_fold} alt="fold_icon" style={this.state.isMobileMenuOpen3 ? {paddingLeft: 16, transform:'scaleY(-1)'} : {paddingLeft: 16}}></img>
                            </button>
                            <button className={classes.filterButton} onClick={this.handleMobileMenu4}>
                                <h3 style={{whiteSpace: 'nowrap'}}>Companies {this.state.selectedCompanies.size > 0 ? '(' + this.state.selectedCompanies.size + ')' :''}</h3>
                                <img src={ico_fold} alt="fold_icon" style={this.state.isMobileMenuOpen4 ? {paddingLeft: 16, transform:'scaleY(-1)'} : {paddingLeft: 16}}></img>
                            </button>
                            
                        </div>



                        <div className={classes.jobItems} style={this.state.results.length > 0 ? {display:'block'}: {display:'none'}}>
                            <div className={classes.topPagination}>
                                <p className="cap" style={{color: Color.grey}}>{this.state.total} Jobs</p>
                                <div style={{display:'flex'}}>
                                    <LinkButton style={{paddingRight: 32, display: 'flex', alignItems: 'center'}} disabled={this.state.leftEnd} onClick={this.handleLeft}>
                                        <img src={this.state.leftEnd ? ico_left_g : ico_left_b} alt="icon" style={{width: 32, paddingRight: 4}}></img>
                                        <p>Previous</p>
                                    </LinkButton>
                                    <LinkButton style={{display: 'flex', alignItems: 'center'}} disabled={this.state.rightEnd} onClick={this.handleRight}>
                                        <p>Next</p> 
                                        <img src={this.state.rightEnd ? ico_right_g : ico_right_b} alt="icon" style={{width: 32, paddingLeft: 4}}></img>
                                    </LinkButton>
                                </div>
                            </div>
                            <div className={classes.jobList}>
                                {this.state.showList.map(l => {
                                    var temp =  Array.isArray(l.type) ? this.titleCase(l.type.join(' ').replaceAll("_", " ")) : (l.type ? this.titleCase(l.type.replaceAll("_", " ")) : "")
                                    if(Contract.hasOwnProperty(temp)) temp = Contract[temp];
                                    return(
                                        <JobListCard id={l.id} key={l.id} company={l.company} name={l.title} logo={l.logo} type={temp} location={l.ipgLocation !== null ? (l.ipgLocation.province !== null && l.ipgLocation.city != null ? l.ipgLocation.city + ", " + l.ipgLocation.province : 
                                        (l.ipgLocation.province !== null ? l.ipgLocation.province : (l.ipgLocation.city != null ? l.ipgLocation.city  : ""))) : "" } date={l.postingTime}/>
                                    )
                                })}
                            </div>
                            <div className={classes.bottomPagination}>
                                <p className="cap" style={{color: Color.grey}}>Page {this.state.currentPage} Out Of {this.state.totalPage}</p>
                                <div style={{display:'flex'}}>
                                    <LinkButton style={{paddingRight: 32, display: 'flex', alignItems: 'center'}} disabled={this.state.leftEnd} onClick={this.handleLeft}>
                                        <img src={this.state.leftEnd ? ico_left_g : ico_left_b} alt="icon" style={{width: 32, paddingRight: 4}}></img>
                                        <p>Previous</p>
                                    </LinkButton>
                                    <LinkButton style={{display: 'flex', alignItems: 'center'}} disabled={this.state.rightEnd} onClick={this.handleRight}>
                                        <p>Next</p> 
                                        <img src={this.state.rightEnd ? ico_right_g : ico_right_b} alt="icon" style={{width: 32, paddingLeft: 4}}></img>
                                    </LinkButton>
                                </div>
                            </div>
                        </div>
                        <div className={classes.jobItemsEmpty} style={this.state.results.length > 0 ? {display:'none'}: {display:'flex'}}>
                                <img src={ico_badge} alt="icon" style={{width:128, paddingBottom: 16}}></img>
                                <h3 style={{paddingBottom: 16}}>Sorry, there are no results.</h3>
                                <p>There are no available jobs based on your search criteria. Please change your search criteria and try again.</p>
                        </div>
                    </div> */}
                </div>
                
                <div className={`${classes.cardBlock} ${classes.block}`} style={{flexWrap:'wrap', flexDirection:'row'}}>
                    <div className={classes.greatePlace}>
                        <div className={classes.bottomContent} style={{display:'flex', flexDirection:'column', alignItems:'inherit'}}>
                            <div className={`mainBottom h1`} style={{color: Color.white, maxWidth: 474}} role="heading" aria-level="1">Great Place To Work&trade; every year since 2022</div>     
                            <div>
                                <div  className={classes.bottomLine} style={{width: 111, height:1, backgroundColor:Color.white }}></div>
                                <p className={`normal`} style={{color:Color.white, maxWidth: 474}} role="heading" aria-level="2">Recognized as a Great Place to Work in 2022 and 2023, IntelliPro is proud to announce that we’ve been awarded this distinction once again for 2024-2025.</p>
                            </div>
                        </div>
                        <a href="http://www.greatplacetowork.com/certified-company/7038214" title="Rating and Review" target="_blank" rel="noreferrer">
                            <img src="https://www.greatplacetowork.com/images/profiles/7038214/companyBadge.png" alt="Review" width="120" className={classes.greatePlaceImg}/>
                        </a>
                    </div>
                    <div className={classes.greatePlace}>

                    </div>
                    {/* <img src={BottomImg} alt="great company" className={classes.bottomImage}></img> */}
                </div>
                {/* {renderMobileMenu1}
                {renderMobileMenu2}
                {renderMobileMenu3}
                {renderMobileMenu4} */}
                {Popup}
            </div>
        )

    }
}

export default withStyles(style)(JobSeeker);