import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Color } from '../../assets/color';
import { withRouter } from 'react-router-dom';
import SqrBtnMain from '../../components/squareBtnMain';
import Jobs from '../../data/career';
import JoinImg from '../../assets/joinus/joinHeaderImg.jpg';
import ico_down from '../../assets/icons/ico_arrow_down.svg';
import ServiceJoinCard from '../../components/serviceJoinCard';
import CareerJobCard from '../../components/careerJobCard';

import ico_talent from '../../assets/icons/ico_talent.svg'
import ico_consulting from '../../assets/icons/ico_consulting.svg'
import ico_recruitment from '../../assets/icons/ico_recruitment.svg'
import ico_human_resource from '../../assets/icons/ico_human_resource.svg'
import ico_business_process from '../../assets/icons/ico_business_process.svg'
import ico_manage_service from '../../assets/icons/ico_manage_service.svg'
import ico_money from '../../assets/icons/ico_money.svg';
import ico_medical from '../../assets/icons/ico_medical.svg';
import ico_savings from '../../assets/icons/ico_savings.svg';
import ico_badge from '../../assets/icons/ico_badge.svg';
import ico_multi_people from '../../assets/icons/ico_multi_people.svg';
import ico_remote_work from '../../assets/icons/ico_remote_work.svg';
import ico_training from '../../assets/icons/ico_training.svg';
import ico_happy_emoji from '../../assets/icons/ico_happy_emoji.svg';
import ico_direct from '../../assets/icons/ico_direct.svg';
import ico_path from '../../assets/icons/ico_path.svg'


import BottomImg from '../../assets/home/bottomImg.jpeg';
import GreatPlaceImg from '../../assets/home/greatPlace.png';
import jobDetail from '../../components/jobDetail';

const style = theme => ({
    header:{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        [theme.breakpoints.up('mob')]: {
        },
        [theme.breakpoints.up('tabletv')]: {
            height: 480
        },
        [theme.breakpoints.up('tableth')]: {
            height: 640
        },
        [theme.breakpoints.up('desktop')]: {
            height: 800
        },
        [theme.breakpoints.up('largedesktop')]: {
            
        },
    },
    // headerImg: {
    //     objectFit: 'cover',
    //     [theme.breakpoints.up('mob')]: {
    //         width: '100%',
    //         height: 300
    //     },
    //     [theme.breakpoints.up('tabletv')]: {
           
    //         height: 480
    //     },
    //     [theme.breakpoints.up('join')]: {
    //         width: '50%',
    //     },
    // },
    headerImg: {
        objectFit: 'cover',
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            height: 288
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '50%',
            height: 480
        },
        [theme.breakpoints.up('tableth')]: {
            height: 640
        },
        [theme.breakpoints.up('desktop')]: {
            height: 800
        },
    },
    // headerTitle: {
    //     position: 'relative',
    //     [theme.breakpoints.up('mob')]: {
    //         width: '100%',
    //     },
    //     [theme.breakpoints.up('join')]: {
    //         width: '50%',
    //     },
    // },
    headerTitle: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
       
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            height: 288,
            justifyContent: 'center',
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '50%',
            height: 480
        },
        [theme.breakpoints.up('tableth')]: {
            height: 640
        },
        [theme.breakpoints.up('desktop')]: {
            height: 800,
            justifyContent: 'flex-start',
        },
    },
    title:{
        [theme.breakpoints.up('mob')]: {
            padding: '120px 24px',
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '120px 24px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        [theme.breakpoints.up('join')]: {
            padding: '120px 0px 32px 120px ',
            alignItems: 'unset',
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '120px 0px 32px 240px ',
        },
    },
    joinTitle: {
        [theme.breakpoints.up('mob')]: {
            paddingBottom: 40,
        },
        [theme.breakpoints.up('tabletv')]: {
            paddingBottom: 60,
        },
    },
    serviceTitle: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            paddingBottom: 32,
        },
        [theme.breakpoints.up('desktop')]: {
            width:  '50%',
            paddingBottom: 0,
            maxWidth: 480
        },
        [theme.breakpoints.up('largedesktop')]: {
            width:  '50%',
            maxWidth: 600
        }
    },
    servicePara: {
        [theme.breakpoints.up('mob')]: {
            width: '100%'
        },
        [theme.breakpoints.up('desktop')]: {
            width:  '50%'
        }
    },
    main: {
        [theme.breakpoints.up('mob')]: {
            padding: '0px 24px'
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
    },
    block: {
        display:'flex', 
        flexDirection:'row', 
        justifyContent:'space-between', 
    },
    cardBlock: {
        display:'flex',
        flexDirection:'column',
        [theme.breakpoints.up('mob')]: {
            paddingTop: 80,
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: 120
        },
        [theme.breakpoints.up('desktop')]: {
            paddingTop: 200,
        },
    },
    cardGroup: {
        [theme.breakpoints.up('mob')]: {
            padding: '0px 40px',
        },
        [theme.breakpoints.up('tabletv')]: {
            paddingBottom: 32,
            paddingTop: 80,
            margin: -12
        },
        [theme.breakpoints.up('tableth')]: {
            paddingBottom: 60,
            margin: -16
        },
        [theme.breakpoints.up('desktop')]: {
            paddingBottom: 80,
            margin: -20
        }
    },
    jobItemsEmpty: {
        height: 592,
        width: 'calc(100% - 80px)',
        [theme.breakpoints.up('mob')]: {
            margin: '32px 0px 80px'
        },
        [theme.breakpoints.up('tabletv')]: {
            margin: '40px 0px 120px'
        },
        [theme.breakpoints.up('tableth')]: {
            margin: '60px 0px 120px'
        },
        [theme.breakpoints.up('desktop')]: {
            margin: '80px 0px 200px'
        },
        backgroundColor: Color.lightBlue,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 40,
    },
    jobItems: {
        flexWrap: 'wrap',
        [theme.breakpoints.up('mob')]: {
            margin: '32px -12px 80px'
        },
        [theme.breakpoints.up('tabletv')]: {
            margin: '40px -12px 120px'
        },
        [theme.breakpoints.up('tableth')]: {
            margin: '60px -12px 120px'
        },
        [theme.breakpoints.up('desktop')]: {
            margin: '80px -20px 200px'
        },
    },
    button: {
        backgroundColor: Color.intelligentBlue,
        borderRadius: 0,
        border: 0,
        color: Color.white,
        fontFamily: 'sofia-pro',
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: 600,
        marginTop: 48,
        width: 305,
        height: 64,
        display: 'flex',
        flexDirection:'column',
        justifyContent:'center',
        boxShadow:'0px 2px 8px rgba(0, 0, 0, 0.08)',
        '&:active': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED'
        },
        '&:focus': {
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #377DED'
        },
        '&:hover': {
            cursor: 'pointer',
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED',
            boxShadow:'0px 8px 24px rgba(55, 125, 237, 0.1)',
        },
        '&:disabled': {
            color: Color.grey,
            backgroundColor: Color.borderGrey
        }
    },
    headerbutton: {
        [theme.breakpoints.up('mob')]: {
            display:'none'
        },
        [theme.breakpoints.up('desktop')]: {
            display:'flex',
            position: 'absolute',
            bottom: 0,
            right: 0
        }
    },
})

class Join extends React.Component {
        constructor() {
            super()
        }

        render() {

            const{ classes } = this.props

            return(
                <div>
                    <div className={classes.header}>
                        <div className={classes.headerTitle}>
                            <div className={classes.title}>
                                <div>
                                    <title className={classes.joinTitle}>Join Our team</title>
                                    <div style={{display: 'flex'}}>
                                        <a href="http://www.greatplacetowork.com/certified-company/7038214" title="Rating and Review" target="_blank" rel="noreferrer">
                                            <img src="https://www.greatplacetowork.com/images/profiles/7038214/companyBadge.png" alt="Review" width="120" style={{marginRight: 25}}/>
                                        </a>
                                        <p className="big" style={{maxWidth: 380}} role="heading" aria-level="2">IntelliPro is honored to officially be designated as a Great Place To Work™ every year since 2022.</p>
                                    </div>
                                </div>
                                <div className={classes.headerbutton}>
                                    <a href='/recruiting#form'>
                                        <SqrBtnMain icons={ico_down} content={'Join IntelliPro'} />
                                    </a>
                                </div>
                               
                            </div>
                        </div>
                        <img src={JoinImg} alt="Recruiting & Staffing" className={classes.headerImg}></img>

                    </div>
                  
                    <div className={classes.main}>
                        <div className={`${classes.block} ${classes.cardBlock}`} style={{flexDirection:'row', flexWrap:'wrap', alignItems: 'flex-start'}}>
                            <div className={`h1 ${classes.serviceTitle}`} role="heading" aria-level="1">
                                Take Your Career to the Next Level
                            </div>
                            <p className={`normal ${classes.servicePara}`}>IntelliPro is growing! Come grow with us. If you’re entrepreneurial, team-oriented and dedicated to providing exceptional service while making a meaningful difference in people’s careers, we want to hear from you.</p>
                        </div>
                        <div className={`${classes.block} ${classes.cardBlock}`} style={{flexDirection:'row', flexWrap:'wrap', alignItems: 'flex-start'}}>
                            <div className={`h1 ${classes.serviceTitle}`} role="heading" aria-level="1">
                                Search IntelliPro Job Openings
                            </div>
                            <p className={`normal ${classes.servicePara}`}>IntelliPro is an equal opportunity employer. Qualified applicants will receive consideration for employment without regard to race, color, religion, sex, sexual orientation, gender identity, national origin, disability, protected veteran status, or any other status protected under the law.</p>
                        </div>
                    
                        <div className={classes.jobItemsEmpty} style={Jobs.length > 0 ? {display:'none'}: {display:'flex'}}>
                                <img src={ico_badge} alt="icon" style={{width:128, paddingBottom: 16}}></img>
                                <h3 style={{paddingBottom: 16}}>Sorry, no opening positions at this time.</h3>
                                <p>Currently we don't have any open positions but if you are interested in possible </p>
                                <p>future opportunities,  please send us your resume.</p>
                                <a href="mailto:hr@intelliprogroup.com">
                                    <button className={classes.button}>Send us your resume</button>
                                </a>
                        </div>
                        <div className={classes.jobItems} style={Jobs.length > 0 ? {display:'flex'}: {display:'none'}}>
                            {
                                Jobs.map((job, index)=> {
                                    return(
                                        <CareerJobCard id={job.id} name={job.name} type={job.type} city={job.location} key={index} />
                                    )
                                })
                            }
                        </div>
                        <div className={`${classes.block} ${classes.cardBlock}`} style={{flexDirection:'column'}}>
                            <div className={`h1 ${classes.serviceTitle}`} role="heading" aria-level="1">
                                What We Offer
                            </div>
                            <div className={`${classes.block} ${classes.cardGroup}`} style={{justifyContent:'flex-start', flexWrap:'wrap'}}>
                                <ServiceJoinCard icons={ico_money} content={"Competitive Compensation"}/>
                                <ServiceJoinCard icons={ico_medical} content={"Medical, Dental and Vision Insurance"}/>
                                <ServiceJoinCard icons={ico_savings} content={"401(k) Plan"}/>
                                <ServiceJoinCard icons={ico_badge} content={"Professional Development Opportunities"}/>
                                <ServiceJoinCard icons={ico_multi_people} content={"A Deep Commitment to Diversity & Inclusion"}/>
                                <ServiceJoinCard icons={ico_remote_work} content={"Remote Work Opportunities"}/>
                                <ServiceJoinCard icons={ico_training} content={"Training and Mentorship from Top Experts"}/>
                                <ServiceJoinCard icons={ico_happy_emoji} content={"A Supportive and Fun Company Culture"}/>
                                <ServiceJoinCard icons={ico_direct} content={"Direct Access to Leadership"}/>
                                <ServiceJoinCard icons={ico_path} content={"Clearly Defined Career Paths"}/>
                            </div>
                            
                        </div>
                    </div>
                </div>
            )
        }

}

export default withStyles(style)(withRouter(Join))