import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Color } from '../../assets/color';
import SqrBtnMain from '../../components/squareBtnMain';
import PersonCard from '../../components/personCard';

import AboutUsImg from '../../assets/aboutus/aboutus.jpeg';
import right_icon_white from '../../assets/icons/ico_arrow_right_w.svg';
import NMSDC from '../../assets/aboutus/NMSDC.jpeg';
import P1 from '../../assets/aboutus/Photo_Yisu.jpeg';
import P2 from '../../assets/aboutus/Photo_EllaZheng.jpeg'
import P3 from '../../assets/aboutus/Photo_KevinSturge.jpeg';
import P4 from '../../assets/aboutus/Photo_AntoineBrown.jpeg';
import P5 from '../../assets/aboutus/Photo_JustinCross.jpeg';
import P6 from '../../assets/aboutus/Photo.jpeg';
import P7 from '../../assets/aboutus/Photo_David.png';
import P8 from '../../assets/aboutus/Photo_Melissa.png';
import P9 from '../../assets/aboutus/Photo_carolLiang.png';
import sunny from '../../assets/aboutus/Sunny.jpg';
import swapna from '../../assets/aboutus/Swapna.jpg';
import nick from '../../assets/aboutus/Nick.jpg';
import toshi from '../../assets/aboutus/toshi.JPEG';
import rebecca from '../../assets/aboutus/Rebecca.jpg';
import carol from '../../assets/aboutus/Carol.jpg';
import david from '../../assets/aboutus/David.jpg'

import BottomImg from '../../assets/home/bottomImg.jpeg';
import GreatPlaceImg from '../../assets/home/greatPlace.png';
import { Helmet } from 'react-helmet';


const style = theme => ({
    header: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        [theme.breakpoints.up('mob')]: {
            height: 518
        },
        [theme.breakpoints.up('tabletv')]: {
            height: 480
        },
        [theme.breakpoints.up('tableth')]: {
            height: 640
        },
        [theme.breakpoints.up('desktop')]: {
            height: 800
        },
        [theme.breakpoints.up('largedesktop')]: {

        },
    },
    headerImg: {
        objectFit: 'cover',
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            height: 288
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '50%',
            height: 480
        },
        [theme.breakpoints.up('tableth')]: {
            height: 640
        },
        [theme.breakpoints.up('desktop')]: {
            height: 800
        },
    },
    headerTitle: {
        position: 'relative',
        [theme.breakpoints.up('mob')]: {
            width: '100%',
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '50%',
            height: 480
        },
        [theme.breakpoints.up('tableth')]: {
            height: 640
        },
        [theme.breakpoints.up('desktop')]: {
            height: 800
        },
    },
    title: {
        [theme.breakpoints.up('mob')]: {
            padding: '60px 24px',
            width: 240
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '176px 32px 0px',
            width: 288
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '256px 32px 0px',
            width: 360
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '208px 120px 0px',
            width: '80%',
            maxWidth: 480
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '240px 240px 0px',
        },
    },
    button: {
        [theme.breakpoints.up('mob')]: {
            display: 'none'
        },
        [theme.breakpoints.up('desktop')]: {
            display: 'flex',
            position: 'absolute',
            bottom: 0,
            right: 0
        }
    },
    serviceTitle: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            paddingBottom: 32,
        },
        [theme.breakpoints.up('desktop')]: {
            width: '50%',
            paddingBottom: 0,
            maxWidth: 480
        },
        [theme.breakpoints.up('largedesktop')]: {
            width: '50%',
            maxWidth: 600
        }
    },
    servicePara: {
        [theme.breakpoints.up('mob')]: {
            width: '100%'
        },
        [theme.breakpoints.up('desktop')]: {
            width: '50%'
        }
    },
    main: {
        [theme.breakpoints.up('mob')]: {
            padding: '0px 24px'
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
    },
    block: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    cardBlock: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('mob')]: {
            paddingTop: 80,
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: 120
        },
        [theme.breakpoints.up('desktop')]: {
            paddingTop: 200,
        },
    },
    NMSDCBlock: {
        width: '100%',
        border: '1.5px solid #E8E8E8',
        [theme.breakpoints.up('mob')]: {
            marginTop: 40
        },
        [theme.breakpoints.up('tableth')]: {
            marginTop: 60
        },
        [theme.breakpoints.up('desktop')]: {
            marginTop: 80
        },
        [theme.breakpoints.up('largeesktop')]: {
            marginTop: 120
        },
    },
    NMSDC: {
        [theme.breakpoints.up('mob')]: {
            padding: 24,
            justifyContent: 'center'
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: 40,
            justifyContent: 'space-between'
        },
        [theme.breakpoints.up('desktop')]: {
            padding: 60
        },
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start',

    },
    NMSDCImg: {
        [theme.breakpoints.up('mob')]: {
            width: 118,
            paddingBottom: 24
        },
        [theme.breakpoints.up('tabletv')]: {
            width: 140,
        },
        [theme.breakpoints.up('tableth')]: {
            width: 178,
        },
    },
    NMSDCPara: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
        },
        [theme.breakpoints.up('tabletv')]: {
            minWidth: 250,
            width: 'calc(100% - 172px)',
        },
        [theme.breakpoints.up('tableth')]: {
            width: 'calc(100% - 210px)',
        },
        [theme.breakpoints.up('desktop')]: {
            width: 'calc(100% - 226px)',
        },

    },
    hrTitle: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
        },
        [theme.breakpoints.up('tableth')]: {
            width: '50%',
        },
    },
    inclusionBlock: {
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        paddingTop: 80,
        [theme.breakpoints.up('mob')]: {
            display: 'none',
        },
        [theme.breakpoints.up('desktop')]: {
            display: 'flex',
        },
    },
    inclusionBlock1: {
        paddingTop: 32,
        width: '100%',
        flexDirection: 'column',
        [theme.breakpoints.up('mob')]: {
            display: 'flex',
        },
        [theme.breakpoints.up('desktop')]: {
            display: 'none',
        },
    },
    inclusionList1: {
        listStyleType: 'square',
        paddingTop: 48,
        margin: 0
    },
    inclusionPara: {
        width: 'calc(50% - 80px)'
    },
    inclusionList: {
        listStyleType: 'square',
        width: 'calc(50% - 40px)',
        margin: 0
    },
    inclusion: {
        color: Color.midGrey,
        fontWeight: 400,
        fontSize: 20,
        letterSpacing: '0.04rem',
        lineHeight: '32px',
        '&::marker': {
            color: Color.intelligentBlue
        }
    },
    leadshipGroup: {
        display: 'flex',
        // flexDirection: 'column',
        flexWrap: 'wrap',
        columnGap: 38,
        rowGap: 30,
        [theme.breakpoints.up('mob')]: {
            paddingTop: '40px',
            margin: -12,
            width: 'calc(100% + 24px)',
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: '60px',
            margin: -16,
            width: 'calc(100% + 32px)',
        },
        [theme.breakpoints.up('desktop')]: {
            paddingTop: '80px',
            margin: -20,
            width: 'calc(100% + 40px)',
        },
    },

    greatePlace: {
        height: 480,
        [theme.breakpoints.up('mob')]: {
            width: '100%',
        },
        [theme.breakpoints.up('desktop')]: {
            width: '50%',
        },
        alignItems: 'center',
        backgroundColor: Color.intelligentBlue,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',

    },
    bottomContent: {
        [theme.breakpoints.up('mob')]: {
            paddingLeft: '32px'
        },
        [theme.breakpoints.up('tableth')]: {
            paddingLeft: '60px'
        },

    },
    bottomLine: {
        [theme.breakpoints.up('mob')]: {
            margin: '40px 0px 15px'
        },
        [theme.breakpoints.up('tableth')]: {
            margin: '40px 0px 15px'
        },

    },
    greatePlaceImg: {
        position: 'relative',
        [theme.breakpoints.up('mob')]: {
            top: -172,
            width: 80,
            paddingRight: '32px'
        },
        [theme.breakpoints.up('tabletv')]: {
            paddingRight: '60px'
        },
        [theme.breakpoints.up('tableth')]: {
            width: 126,
            top: -133
        },
        [theme.breakpoints.up('desktop')]: {
            width: 108,
            top: -148
        },
        [theme.breakpoints.up('largedesktop')]: {
            width: 126,
            top: -133
        },
    },
    bottomImage: {
        height: 480,
        objectFit: 'cover',
        [theme.breakpoints.up('mob')]: {
            width: '100%',
        },
        [theme.breakpoints.up('desktop')]: {
            width: '50%',

        },
    },
})

class AboutUs extends React.Component {
    constructor() {
        super()
    }

    render() {
        const { classes, menuClicked, history } = this.props;

        return (
            <div>
                <Helmet>
                    <title>About IntelliPro</title>
                    <meta property="og:title" content="Talent acquisition firm, minority owned business, diversity, equity, inclusion, great place to work" />
                    <meta property="og:description" content="IntelliPro is one of the fastest growing talent acquisition firms in the United States and Asia-Pacific regions. Proudly certified by the Western Supplier Development Council as a Minority Business Enterprise (MBE). " />
                    <meta name="description" content="IntelliPro is one of the fastest growing talent acquisition firms in the United States and Asia-Pacific regions. Proudly certified by the Western Supplier Development Council as a Minority Business Enterprise (MBE). " />
                </Helmet>
                <div className={classes.header}>
                    <div className={classes.headerTitle}>
                        <title className={classes.title}>About IntelliPro</title>
                        <div className={classes.button}>
                            <SqrBtnMain icons={right_icon_white} content={'Join Our Team'} onClick={() => { history.push('/join'); menuClicked(4) }} />
                        </div>
                    </div>
                    <img src={AboutUsImg} alt="about us" className={classes.headerImg}></img>
                </div>
                <div className={classes.main}>
                    <div className={`${classes.block} ${classes.cardBlock}`} style={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                        <div className={`h1 ${classes.serviceTitle}`}>Our History</div>
                        <p className={`normal ${classes.servicePara}`}>Founded in 2009, IntelliPro is one of the fastest growing talent acquisition firms in the United States and Asia-Pacific region. We have built our business on our company-wide commitment to continually overdeliver on the high expectations of our clients, employees and business partners. The secret to our success is that our unified team works harder, faster, smarter and more collaboratively than anyone else in the talent acquisition business.</p>
                    </div>
                    <div className={`${classes.block} ${classes.NMSDCBlock}`}>
                        <div className={classes.NMSDC}>
                            <img src={NMSDC} alt="NMSDC" className={classes.NMSDCImg}></img>
                            <p className={`normal ${classes.NMSDCPara}`}>IntelliPro is extremely proud to officially be certified by the Western Minority Supplier Development Council as a Minority Business Enterprise (MBE). The WMSDC, one of America’s leading corporate membership organizations, certifies minority-owned businesses and advances their opportunities to work with corporate members. We greatly look forward to expanding our partnerships with business organizations that value supplier diversity and support minority-owned businesses such as IntelliPro.</p>
                        </div>
                    </div>
                    <div className={`${classes.block} ${classes.cardBlock}`} style={{ flexDirection: 'column' }}>
                        <div className={`h1 ${classes.hrTitle}`} role="heading" aria-level="1">Our Commitment to Diversity, Equity and Inclusion</div>
                        <div className={classes.inclusionBlock}>
                            <div className={classes.inclusionPara}>
                                <p className={`normal`}>
                                    At IntelliPro, we are deeply committed to diversity, equity and inclusion. As a minority-owned business certified by the National Minority Supplier Development Council, we value the array of perspectives our employees and consultants across the globe bring to work each and every day. We continually strive to build a culture that encourages and celebrates diversity. As our growing company evolves, we are dedicated to remaining rooted in our commitment to being an inclusive organization where everyone feels confident being their authentic selves. We have taken a variety of steps to promote our core principles of diversity, equity, inclusivity and cultural understanding, including:
                                </p>
                                <p className={`normal`} style={{ paddingTop: 60 }}>
                                    At IntelliPro, we believe the diversity of perspectives and backgrounds of our employees is the key ingredient to our strength and success. We invite you to partner with IntelliPro as we do our small part in creating a more equitable world.
                                </p>
                            </div>

                            <ul className={classes.inclusionList}>
                                <li className={classes.inclusion}>CEO Yisu Jin signing a public commitment – the CEO Action for Diversity & Inclusion™ Pledge – to advance diversity within IntellIPro and operate as an inclusive company.</li>
                                <li className={classes.inclusion}>Facilitating mandatory diversity and inclusion training for all employees on topics ranging from unconscious bias and racial sensitivity to avoiding microaggressions in the workplace.</li>
                                <li className={classes.inclusion}>Giving our employees a voice to influence meaningful change within the company. Our employee resource group, comprised of representatives from across the organization, identifies opportunities to better support fellow employees and advises our executive team on actionable strategies relating to emerging diversity and inclusion issues.</li>
                                <li className={classes.inclusion}>Providing ongoing training to our recruiting teams to ensure we are adhering to the most inclusive hiring practices.</li>
                                <li className={classes.inclusion}>Instituting a zero-tolerance policy against any form of discrimination, harassment or bullying.</li>
                            </ul>
                        </div>
                        <div className={classes.inclusionBlock1}>
                            <p className={`normal`}>
                                At IntelliPro, we are deeply committed to diversity, equity and inclusion. As a minority-owned business certified by the National Minority Supplier Development Council, we value the array of perspectives our employees and consultants across the globe bring to work each and every day. We continually strive to build a culture that encourages and celebrates diversity. As our growing company evolves, we are dedicated to remaining rooted in our commitment to being an inclusive organization where everyone feels confident being their authentic selves. We have taken a variety of steps to promote our core principles of diversity, equity, inclusivity and cultural understanding, including:
                            </p>
                            <ul className={classes.inclusionList1}>
                                <li className={classes.inclusion}>CEO Yisu Jin signing a public commitment – the CEO Action for Diversity & Inclusion™ Pledge – to advance diversity within IntellIPro and operate as an inclusive company.</li>
                                <li className={classes.inclusion}>Facilitating mandatory diversity and inclusion training for all employees on topics ranging from unconscious bias and racial sensitivity to avoiding microaggressions in the workplace.</li>
                                <li className={classes.inclusion}>Giving our employees a voice to influence meaningful change within the company. Our employee resource group, comprised of representatives from across the organization, identifies opportunities to better support fellow employees and advises our executive team on actionable strategies relating to emerging diversity and inclusion issues.</li>
                                <li className={classes.inclusion}>Providing ongoing training to our recruiting teams to ensure we are adhering to the most inclusive hiring practices.</li>
                                <li className={classes.inclusion}>Instituting a zero-tolerance policy against any form of discrimination, harassment or bullying.</li>
                            </ul>
                            <p className={`normal`} style={{ paddingTop: 48 }}>
                                At IntelliPro, we believe the diversity of perspectives and backgrounds of our employees is the key ingredient to our strength and success. We invite you to partner with IntelliPro as we do our small part in creating a more equitable world.
                            </p>
                        </div>
                    </div>
                    <div className={`${classes.cardBlock} ${classes.block}`} style={{ flexDirection: 'column' }}>
                        <div className={`h1`} style={{ width: "100%" }} role="heading" aria-level="1">
                            Our Leadership Team
                        </div>
                    </div>
                    <div className={classes.leadshipGroup}>
                        <PersonCard img={P1} name="Yisu Jin" title="CEO" link={'https://www.linkedin.com/in/yisujin/'} />
                        <PersonCard img={P2} name="Ella Zheng" title="Global COO" link={'https://www.linkedin.com/in/ellazheng'} />
                        <PersonCard img={david} name="David Gershman" title="Head of Sales & Business Development" link={'https://www.linkedin.com/in/david-gershman-284926/'} />
                        {/*<PersonCard img={P8} name="Melissa Neverson" title="Head of DEI & Director of Brand Marketing" link={'https://www.linkedin.com/in/melissa-neverson-883246139/'} />*/}
                        <PersonCard img={P6} name="Edwin Chang" title="Head of Talent Development" link={'https://www.linkedin.com/in/edwinlihengchang/'} />
                        <PersonCard img={carol} name="Carol Liang" title="Head of HR Service" link={'https://www.linkedin.com/in/mengshi-carol-liang-8ab5a547/'} />
                        <PersonCard img={nick} name="Nick Chen" title="Head of EU" link={'https://www.linkedin.com/in/nick-chen-49b35'} />
                        <PersonCard img={sunny} name="Sunny Cui" title="Head of Netherlands" link={'https://www.linkedin.com/in/sunny-cui-uk/'} />
                        <PersonCard img={rebecca} name="Rebecca Fong" title="Head of SEA" link={'https://www.linkedin.com/in/rebeccafyh/'} />
                        <PersonCard img={toshi} name="Toshikazu Watanabe" title="Head of Japan" link={'https://www.linkedin.com/in/toshikazu-watanabe-4318366/'} />
                        <PersonCard img={swapna} name="Swapna Ponakampalli" title="Head of India" link={'https://www.linkedin.com/in/swapna-ponakampalli/'} />
                    </div>
                </div>
                <div className={`${classes.cardBlock} ${classes.block}`} style={{ flexWrap: 'wrap', flexDirection: 'row' }}>
                    <div className={classes.greatePlace}>
                        <div className={classes.bottomContent} style={{ display: 'flex', flexDirection: 'column', alignItems: 'inherit' }}>
                            <div className={`h1 mainBottom`} style={{ color: Color.white, width: '100%' }} role="heading" aria-level="1">
                                Join Our Team
                            </div>
                            <div>
                                <div className={classes.bottomLine} style={{ width: 111, height: 1, backgroundColor: Color.white }}></div>
                                <p className={`normal`} style={{ color: Color.white, maxWidth: 474 }} role="heading" aria-level="2">Recognized as a Great Place to Work in 2022 and 2023, IntelliPro is proud to announce that we’ve been awarded this distinction once again for 2024-2025.</p>
                            </div>
                        </div>
                        <a href="http://www.greatplacetowork.com/certified-company/7038214" title="Rating and Review" target="_blank" rel="noreferrer">
                            <img src="https://www.greatplacetowork.com/images/profiles/7038214/companyBadge.png" alt="Review" width="120" className={classes.greatePlaceImg} />
                        </a>

                    </div>
                    <div className={classes.greatePlace}>

                    </div>
                    {/* <img src={BottomImg} alt="great company" className={classes.bottomImage}></img> */}
                </div>
            </div>
        )
    }
}

export default withStyles(style)(withRouter(AboutUs));